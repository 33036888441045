<template>
  <div>
    <base-img
      :src="require('@/assets/orion2.png')"
      class="mt-0"
      contain
      width="250"
      style="height: 55px"
    />
    <base-body class="mt-10">
      <p style="font-size: 18px">
        <b class="white--text">G</b> i g i h<br />
        <b class="white--text">R</b> a m a h<br /><b class="white--text">A</b> d
        a f t i f<br /><b class="white--text">V</b> i s i o n e r<br /><b
          class="white--text"
          >I</b
        >
        n o v a t i f<br />
        <b class="white--text">T</b> e r p e r c a y a<br />
        <b class="white--text">A</b> n t u s i a s <br /><b class="white--text"
          >S</b
        >
        o l i d T e a m w o r k<br /><b class="white--text">I</b> n t e g r i t
        a s
      </p>
    </base-body>
    <!-- <div id="google_translate_element"></div> -->
  </div>
</template>
<script>
export default {
  name: "BaseInfo",
  props: {
    title: String,
    dark: Boolean,
  },

  data: () => ({
    business: [
      {
        icon: "mdi-map-marker-outline",
        title: "Address",
        text: "Gedung M Ten Lantai 25, Jl. Kuningan Barat Raya No.1, Kota Jakarta Selatan DKI Jakarta",
      },
      {
        icon: "mdi-cellphone",
        title: "Phone",
        text: "01 (800) 433 744<br>01 (800) 433 633",
      },
      {
        icon: "mdi-email",
        title: "Email",
        text: "john@vuetifyjs.com<br>heather@vuetifyjs.com",
      },
    ],
  }),
};
</script>
